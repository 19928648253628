import React, { useState } from 'react';
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Input,
  Button,
  Typography,
  Select,
  Option
} from "@material-tailwind/react";
import { recurringEntryRequest } from "../js/entry";
import SimpleBackdrop from './backdrop';
import ResultModal from './resultModal';
import { formatPhoneNumber } from "../helper/phoneHelper";

export function RegisterRecurring({chatId}) {
  const [recurring, setRecurring] = useState({
    name: localStorage.getItem('_vb_vname') != null ? localStorage.getItem('_vb_vname') : '',
    phone: localStorage.getItem('_vb_vphone') != null ? localStorage.getItem('_vb_vphone') : '',
    vehicle_number: localStorage.getItem('_vb_vvnum') != null ? localStorage.getItem('_vb_vvnum') : '',
    unit_number: '',
    type: 'E-Hailing',
  });

  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [modal, setModal] = useState({
    title: '',
    description: '',
  });

  const handleCloseModal = (event) => {
    setOpenModal(false);
  }

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (chatId == null) {
      // setModal({
      //   ...modal,
      //   title: 'Error',
      //   description: 'No Chat Id found in request'
      // });
      // setOpenModal(true);
      // return;
      chatId = process.env.REACT_APP_CHAT_ID;
    }

    setOpenBackdrop(true);

    await recurringEntryRequest(chatId, recurring)
      .then((response) => {
        localStorage.setItem('_vb_mid', response.data.data.message_id);
        localStorage.setItem('_vb_stitle', response.data.data.sheet_title);
        localStorage.setItem('_vb_vname', recurring.name);
        localStorage.setItem('_vb_vphone', recurring.phone);
        localStorage.setItem('_vb_vvnum', recurring.vehicle_number);

        setOpenBackdrop(false);
        clearForm();
        setModal({
          ...modal,
          title: 'Success',
          description: 'Gate entry request successfully sent for approval'
        });
        setOpenModal(true);
      }).catch((error) => {
        setOpenBackdrop(false);
      });
  }

  const handleChange = (event) => {
    setRecurring({
      ...recurring,
      [event.target.name]: event.target.value
    });
  }

  function phoneNumberFormatter(phoneNumber, setVisitor) {
    var formattedPhoneNumber = formatPhoneNumber(phoneNumber);
    setRecurring({
      ...recurring,
      phone: formattedPhoneNumber
    });
  }


  const handleSelectChange = (value) => {
    setRecurring({
      ...recurring,
      type: value
    });
  }

  const clearForm = () => {
    setRecurring({
      ...recurring,
      name: localStorage.getItem('_vb_vname') != null ? localStorage.getItem('_vb_vname') : '',
      phone: localStorage.getItem('_vb_vphone') != null ? localStorage.getItem('_vb_vphone') : '',
      vehicle_number: localStorage.getItem('_vb_vvnum') != null ? localStorage.getItem('_vb_vvnum') : '',
      unit_number: '',
      type: "E-Hailing"
    });
  }

  var rows = [], i = 0, len = 30;
  while (++i <= len) rows.push(i);

  return (
    <>
      <img
        src="/img/background.png"
        className="absolute inset-0 z-0 h-full w-full object-cover"
        alt=""
      />
      <SimpleBackdrop open={openBackdrop}></SimpleBackdrop>
      <div className="absolute inset-0 z-0 h-full w-full bg-black/50" />
      <div className="container mx-auto p-4">
        <form onSubmit={handleSubmit}>
          <Card className="absolute top-2/4 left-2/4 w-full max-w-[24rem] -translate-y-2/4 -translate-x-2/4">
            <CardHeader
              variant="gradient"
              color="blue"
              className="mb-4 grid h-16 place-items-center"
            >
              <Typography variant="h4" color="white" className="text-center">
                E-Hailing/Food Delivery/Courier Entry Registration
              </Typography>
            </CardHeader>
            <CardBody className="flex flex-col gap-4">
              <Input 
                required
                label="Name * " 
                size="lg" 
                name="name"
                value={recurring.name} 
                onChange={handleChange} 
                className="uppercase" />
              <Input 
                required
                type="phone" 
                label="Phone * " 
                size="lg" 
                name="phone"
                value={recurring.phone} 
                onChange={e => phoneNumberFormatter(e.target.value, setRecurring)} 
                className="uppercase" />
              <Input 
                required
                label="Vehicle Number * " 
                size="lg" 
                name="vehicle_number"
                value={recurring.vehicle_number}
                onChange={handleChange} 
                className="uppercase" />
              <Input
                required 
                label="Unit Number * " 
                size="lg"
                name="unit_number"
                value={recurring.unit_number}
                onChange={handleChange} 
                className="uppercase" />
              <Select 
                required
                label="Select Type * " 
                size="lg"
                value={recurring.type} 
                name="type"
                onChange={handleSelectChange}>
                <Option value="E-Hailing">E-Hailing</Option>
                <Option value="Food Deliver">Food Delivery</Option>
                <Option value="Courier">Courier</Option>
                <Option value="Contractor">Contractor</Option>
            </Select>
            </CardBody>
            <CardFooter className="pt-4">
              <Button variant="gradient" fullWidth type="submit">
                Submit
              </Button>
            </CardFooter>
          </Card>
        </form>
      </div>
      <div className="container absolute bottom-6 left-2/4 z-10 mx-auto -translate-x-2/4 text-white"> 
      </div>
      <ResultModal open={openModal} handleClose={handleCloseModal} modal={modal}></ResultModal>
    </>
  );
}

export default RegisterRecurring;
