import React, { useState } from 'react';
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Input,
  Button,
  Typography,
  Select,
  Option,
  Radio
} from "@material-tailwind/react";
import { visitorEntryRequest } from "../js/entry";
import SimpleBackdrop from './backdrop';
import ResultModal from './resultModal';
import { formatPhoneNumber } from "../helper/phoneHelper";

//TODO add oauth for recurring

export function Register({chatId}) {
  const [visitor, setVisitor] = useState({
    name: localStorage.getItem('_vb_vname') != null ? localStorage.getItem('_vb_vname') : '',
    phone: localStorage.getItem('_vb_vphone') != null ? localStorage.getItem('_vb_vphone') : '',
    vehicle_number: localStorage.getItem('_vb_vvnum') != null ? localStorage.getItem('_vb_vvnum') : '',
    unit_number: '',
    duration_days: '1',
  });

  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [modal, setModal] = useState({
    title: '',
    description: '',
  });

  const [hideVehicleNumber, setHideVehicleNumber] = useState(localStorage.getItem('_vb_vtype') != null ? localStorage.getItem('_vb_vtype') : 'no');

  const handleCloseModal = (event) => {
    setOpenModal(false);
  }

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (chatId == null) {
      //setModal({
      //  ...modal,
      //  title: 'Error',
      //  description: 'No Chat Id found in request'
      //});
      //setOpenModal(true);
      //return;
      chatId = process.env.REACT_APP_CHAT_ID;
    }

    setOpenBackdrop(true);

    await visitorEntryRequest(chatId, visitor)
      .then((response) => {
        localStorage.setItem('_vb_mid', response.data.data.message_id);
        localStorage.setItem('_vb_stitle', response.data.data.sheet_title);
        localStorage.setItem('_vb_vname', visitor.name);
        localStorage.setItem('_vb_vphone', visitor.phone);
        localStorage.setItem('_vb_vvnum', visitor.vehicle_number);
        localStorage.setItem('_vb_vtype', hideVehicleNumber);
        
        setOpenBackdrop(false);
        clearForm();
        setModal({
          ...modal,
          title: 'Success',
          description: 'Visitor entry request successfully sent for approval'
        });
        setOpenModal(true);
      }).catch((error) => {
        setOpenBackdrop(false);
      });
  }

  const handleChange = (event) => {
    setVisitor({
      ...visitor,
      [event.target.name]: event.target.value
    });
  }

  function phoneNumberFormatter(phoneNumber, setVisitor) {
    var formattedPhoneNumber = formatPhoneNumber(phoneNumber);
    setVisitor({
      ...visitor,
      phone: formattedPhoneNumber
    });
  }

  const handleSelectChange = (value) => {
    setVisitor({
      ...visitor,
      duration_days: value
    });
  }

  const handleTypeChange = (value) => {
    setHideVehicleNumber(value);
    if (value === 'yes') {
      setVisitor({
        ...visitor,
        vehicle_number: 'Walk-In'
      });

      return;
    }

    if (value === 'no') {
      setVisitor({
        ...visitor,
        vehicle_number: ''
      });

      return;
    }
  }

  const clearForm = () => {
    setVisitor({
      ...visitor,
      name: localStorage.getItem('_vb_vname') != null ? localStorage.getItem('_vb_vname') : '',
      phone: localStorage.getItem('_vb_vphone') != null ? localStorage.getItem('_vb_vphone') : '',
      vehicle_number: localStorage.getItem('_vb_vvnum') != null ? localStorage.getItem('_vb_vvnum') : '',
      unit_number: '',
      duration_days: "1"
    });
  }

  var rows = [], i = 0, len = 1;
  while (++i <= len) rows.push(i);

  return (
    <>
      <img
        src="/img/background.png"
        className="absolute inset-0 z-0 h-full w-full object-cover"
        alt=""
      />
      <SimpleBackdrop open={openBackdrop}></SimpleBackdrop>
      <div className="absolute inset-0 z-0 h-full w-full bg-black/50" />
      <div className="container mx-auto p-4">
        <form onSubmit={handleSubmit}>
          <Card className="absolute top-2/4 left-2/4 w-full max-w-[24rem] -translate-y-2/4 -translate-x-2/4">
            <CardHeader
              variant="gradient"
              color="blue"
              className="mb-4 grid h-16 place-items-center"
            >
              <Typography variant="h4" color="white">
                Entry Registration
              </Typography>
            </CardHeader>
            <CardBody className="flex flex-col gap-4">
              <Input 
                required
                label="Name * " 
                size="lg" 
                name="name"
                value={visitor.name} 
                onChange={handleChange}
                className="uppercase" />
              <Input 
                required
                type="phone" 
                label="Phone * " 
                size="lg" 
                name="phone"
                value={visitor.phone} 
                onChange={e => phoneNumberFormatter(e.target.value, setVisitor)} 
                className="uppercase" />
              <div className="flex gap-10">
                <Radio id="vehicle" name="type" label="Vehicle" value="no" onChange={e => handleTypeChange(e.target.value)} checked={hideVehicleNumber === 'no'}/>
                <Radio id="walkin" name="type" label="Walk-In" value="yes" onChange={e => handleTypeChange(e.target.value)} checked={hideVehicleNumber === 'yes'}/>
              </div>
              {hideVehicleNumber === "no" && (
                <Input 
                  required
                  label="Vehicle Number * " 
                  size="lg" 
                  name="vehicle_number"
                  value={visitor.vehicle_number}
                  onChange={handleChange}
                  className="uppercase" />
              )}
              <Input 
                required
                label="Unit Number * " 
                size="lg"
                name="unit_number"
                value={visitor.unit_number}
                onChange={handleChange}
                className="uppercase" />
              <Select 
                required
                label="Duration (Days) * " 
                size="lg" 
                value={visitor.duration_days} 
                name="duration_days"
                onChange={handleSelectChange}>
              {
                  rows.map(function (i) {
                      return <Option key={i} value={i.toString()} >{i}</Option>;
                  })
              }
              </Select>
            </CardBody>
            <CardFooter className="pt-4">
              <Button variant="gradient" fullWidth type="submit">
                Submit
              </Button>
            </CardFooter>
          </Card>
        </form>
      </div>
      <div className="container absolute bottom-6 left-2/4 z-10 mx-auto -translate-x-2/4 text-white"> 
      </div>
      <ResultModal open={openModal} handleClose={handleCloseModal} modal={modal}></ResultModal>
    </>
  );
}

export default Register;
